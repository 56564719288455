import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import MainNav from "../components/MainNav"
// import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const Header = ({ heading, subheading, background }) => {
  const customStyle = {
    backgroundImage: "url(" + background + ")",
    backgroundRepeat: "no-repeat",
  }

  // const handleCallHome = e => contact => {
  //   e.preventDefault()
  //   document.location.href = `tel:+${contact}`
  //   // Lets track that custom click
  //   trackCustomEvent({
  //     // string - required - The object that was interacted with (e.g.video)
  //     category: "CallNow-Homepage",
  //     // string - required - Type of interaction (e.g. 'play')
  //     action: "Click",
  //     // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
  //     label: "Call Now Homepage",
  //   })
  // }
  // const handleContactHeader = e => {
  //   e.preventDefault()

  //   // Lets track that custom click
  //   trackCustomEvent({
  //     // string - required - The object that was interacted with (e.g.video)
  //     category: "ContactPageHeader",
  //     // string - required - Type of interaction (e.g. 'play')
  //     action: "Click",
  //     // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
  //     label: "Contact Header",
  //   })
  // }

  return (
    <header className="header container" style={customStyle}>
      <div className="header__wrapper  ">
        <div className="nav__right--icon">
          <i className="fas fa-align-right"></i>
        </div>
        {/* <MainNav active="home" /> */}
        <div className="hero">
          <h1 className="hero__heading">{heading}</h1>
          <h2 className="hero__subheading">{subheading}</h2>

          <Link className="hero__btn" to="/strategy-call">
            Book strategy call -{" "}
            <span className="hero__btn--span">It's free</span>
          </Link>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
