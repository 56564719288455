import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { FaFacebookF } from "react-icons/fa"
import { FaTwitter } from "react-icons/fa"
import { FaLinkedinIn } from "react-icons/fa"
import { FaEnvelope } from "react-icons/fa"
import { FaMobileAlt } from "react-icons/fa"
import { FaMapMarkerAlt } from "react-icons/fa"
import { FaAngleDoubleRight } from "react-icons/fa"

const getFooterLogo = graphql`
  {
    footerImg: file(relativePath: { eq: "logofooter.png" }) {
      size
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

const Footer = ({
  facebookLink,
  linkedinLink,
  supportEmail,
  twiiterLink,
  companyAddress,
  companyContactNumber,
  companyOverview,
}) => {
  const {
    footerImg: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(getFooterLogo)

  return (
    <footer className="footer ">
      <div className="footer__col">
        <div className="footer__left">
          <div className="footer__logoWrapper">
            {/* <img
              src={require("../images/logofooter.png")}
              alt="GreatWeb"
              className="footer__logo"
            /> */}
            <Image
              className="footer__logo"
              fluid={fluid}
              alt="About Great Web"
            />
          </div>
          <h5 className="footer__about">{companyOverview}</h5>

          <div className="footer__social--list">
            <a
              href={facebookLink}
              className="footer__social--item"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookF className="footer__social--icon" />
            </a>
            <a
              href={linkedinLink}
              className="footer__social--item"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedinIn className="footer__social--icon" />
            </a>
            <a
              href={twiiterLink}
              className="footer__social--item"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter className="footer__social--icon" />
            </a>
          </div>

          <div className="footer__googlemap absolute">
            <iframe
              title="great web"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3967.0977345345154!2d125.16136751476839!3d6.117544595570417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x32f79f0f14ebbbdf%3A0x670d3967570622ba!2s21%20Apple%2C%20General%20Santos%20City%2C%20South%20Cotabato%2C%20Philippines!5e0!3m2!1sen!2sus!4v1601907795603!5m2!1sen!2sus"
              width="0"
              height="0"
              aria-hidden="false"
            ></iframe>
          </div>
        </div>
        <div className="footer__center">
          <div className="footer__center--quickmenu">
            <h5 className="footer__center--heading">Quick Links</h5>
            <div className="footer__center--col">
              <ul className="footer__center--list">
                <li className="footer__center--item">
                  <Link className="footer__center--link" to="/">
                    <FaAngleDoubleRight className="footer__center--icon" />
                    <span>Home</span>
                  </Link>
                </li>
                <li className="footer__center--item">
                  <Link className="footer__center--link" to="/about">
                    <FaAngleDoubleRight className="footer__center--icon" />
                    <span>Why Us</span>
                  </Link>
                </li>
                <li className="footer__center--item">
                  <Link className="footer__center--link" to="/portfolio">
                    <FaAngleDoubleRight className="footer__center--icon" />
                    <span>Our Work</span>
                  </Link>
                </li>

                <li className="footer__center--item">
                  <Link className="footer__center--link" to="/blog">
                    <FaAngleDoubleRight className="footer__center--icon" />
                    <span> Blog</span>
                  </Link>
                </li>
                <li className="footer__center--item">
                  <Link className="footer__center--link" to="/contact">
                    <FaAngleDoubleRight className="footer__center--icon" />
                    <span>Contact Us</span>
                  </Link>
                </li>
              </ul>

              <ul className="footer__center--list">
                <li className="footer__center--item">
                  <Link
                    className="footer__center--link"
                    to="/webdesign-philippines"
                  >
                    <FaAngleDoubleRight className="footer__center--icon" />
                    <span> Web Design</span>
                  </Link>
                </li>
                <li className="footer__center--item">
                  <Link className="footer__center--link" to="/seo-philippines">
                    <FaAngleDoubleRight className="footer__center--icon" />
                    <span>SEO (Google Ranking)</span>
                  </Link>
                </li>

                <li className="footer__center--item">
                  <Link className="footer__center--link" to="/google-reviews">
                    <FaAngleDoubleRight className="footer__center--icon" />
                    <span>SEO (Google Reviews)</span>
                  </Link>
                </li>
                <li className="footer__center--item">
                  <Link className="footer__center--link" to="/strategy-call">
                    <FaAngleDoubleRight className="footer__center--icon" />
                    <span>Free Strategy Call</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer__right">
          <h5 className="footer__right--contact">Contacts</h5>

          <ul className="footer__right--info--list">
            <li className="footer__right--info--item">
              <FaEnvelope className="footer__right--info--icon" />
              <a
                href={`mailto:${supportEmail}?subject = Feedback&body = Message" ${supportEmail}`}
                className="footer__right--info--label"
              >
                {supportEmail}
              </a>
            </li>
            <li className="footer__right--info--item">
              <FaMobileAlt className="footer__right--info--icon" />
              <div className="footer__right--info--label">
                <a className="text-white" href={`tel:+${companyContactNumber}`}>
                  Click to Call {companyContactNumber}
                </a>
              </div>
            </li>
            <li className="footer__right--info--item">
              <FaMapMarkerAlt className="footer__right--info--icon" />
              <address className="footer__right--info--label">
                {companyAddress}
              </address>
            </li>
          </ul>
        </div>
      </div>

      <hr />

      <div className="footer__copyright">
        <p className="footer__copyright--left">
          Terms of use | Privacy Environmental Policy
        </p>
        <p className="footer__copyright--right">
          Copyright © 2020 GreatWeb Philippines. All Rights Reserved.
        </p>
      </div>
    </footer>
  )
}

export default Footer
