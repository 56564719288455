import React from "react"
import { Link } from "gatsby"

const ReadyStarted = ({ color }) => {
  return (
    <>
      <div className="ready-started">
        <h4
          className={`${
            color === "primary"
              ? "ready-started--heading--primary"
              : "ready-started--heading"
          }`}
        >
          Ready to Get Started?
        </h4>
        <p
          className={`${
            color === "primary"
              ? "ready-started--subheading--primary"
              : "ready-started--subheading"
          }`}
        >
          If you want to learn more about how I can help grow your business,
          click below to schedule a free growth call. If you are ready to start
          right away,{" "}
          <Link
            className={`${
              color === "primary"
                ? "ready-started--link--primary"
                : "ready-started--link"
            }`}
            to="/contact"
          >
            click here to contact me.
          </Link>
        </p>
      </div>
    </>
  )
}

export default ReadyStarted
