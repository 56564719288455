import React, { useState } from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"
import ReadyStarted from "../components/ReadyStarted"
import BookStrategyBtn from "../components/BookStrategyBtn"
import BookStrategyBtnPrimary from "../components/BookStrategyBtnPrimary"
import MainNav from "../components/MainNav"

import { FaCheckCircle } from "react-icons/fa"
import { FaArrowRight } from "react-icons/fa"
import { FaArrowLeft } from "react-icons/fa"
import { FaStar } from "react-icons/fa"

export const getData = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    # focusImg: file(relativePath: { eq: "focus-img.png" }) {
    #   size
    #   childImageSharp {
    #     fluid {
    #       src
    #     }
    #   }
    # }

    # testimonyBG: file(relativePath: { eq: "tbh.png" }) {
    #   size
    #   childImageSharp {
    #     fluid {
    #       src
    #     }
    #   }
    # }

    wpgraphql_greatweb {
      pageBy(uri: "home") {
        title
        pageSiteMetaTitle_acf {
          sitemetatitle
        }
        pageSiteMetaDescription_acf {
          siteMetaDescription
        }
        home_pagesection_acf {
          sections {
            generalSettings {
              announcement
              companyAddress
              companyContactNumber
              companyOverview
              countdownData
              facebookLink
              linkedinLink
              supportEmail
              twiiterLink
            }
            hero {
              subHeading
              heading
              contactNumber
              backgroundImage {
                sourceUrl
              }
            }
            focus {
              paragraph
              listOfFocus
              image {
                sourceUrl
              }
              heading
            }
            principles {
              heading
              principleList
              image {
                sourceUrl
              }
            }
          }
        }
      }
      testimonials {
        nodes {
          testimonial_afc {
            author
            position
            testimony
            photo {
              sourceUrl
            }
          }
        }
      }
      portfolios {
        nodes {
          title
          portfolio_acf {
            description
            results
            services
            websiteLink
            companyLogo {
              sourceUrl
            }
            resultValuePair {
              value
              text
            }
            image {
              sourceUrl
            }
          }
        }
      }
      process {
        nodes {
          title
          process_acf {
            processTitle
            processDescription
            icon {
              sourceUrl
            }
          }
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  // const mainRight = document.querySelector(".main__right")
  // const [height, setHeight] = useState(0)

  // useEffect(() => {
  //   mainRight.addEventListener("scroll", () => {
  //     setHeight(mainRight.pageYOffset)
  //   })

  //   return () => {
  //     mainRight.removeEventListener("scroll", () => {})
  //   }
  // }, [height, setHeight])
  // console.log(height)
  const {
    wpgraphql_greatweb: {
      pageBy: {
        pageSiteMetaTitle_acf: { sitemetatitle },
        pageSiteMetaDescription_acf: { siteMetaDescription },
        home_pagesection_acf: {
          sections: {
            generalSettings: {
              announcement,
              companyAddress,
              companyContactNumber,
              companyOverview,
              countdownData,
              facebookLink,
              linkedinLink,
              supportEmail,
              twiiterLink,
            },
            hero: {
              heading: mainHeading,
              subHeading: mainSubheading,
              contactNumber,
              backgroundImage: { sourceUrl: heroBG },
            },
            focus: {
              heading,
              paragraph,
              image: { sourceUrl },
              listOfFocus: focustList,
            },
            principles: {
              heading: pHeading,
              principleList,
              image: { sourceUrl: pSourceUrl },
            },
          },
        },
      },
      testimonials,
      portfolios,
      process: pProcess,
    },
  } = data

  const footerStyle = {
    backgroundImage: "url(" + require("../images/footer-bg.png") + ")",
  }
  const processStyle = {
    backgroundImage: "url(" + require("../images/process.png") + ")",
  }

  const [nextComment, setNextComment] = useState(0)
  const [activePortfolio, setActivePortfolio] = useState(0)
  const [activeProcess, setActiveProcess] = useState(0)

  const handleActivePortfolio = index => {
    setActivePortfolio(index)
  }

  const handleActiveProcess = index => {
    setActiveProcess(index)
  }

  const handleNextComment = () => {
    const testimonyLength = testimonials.nodes.length - 1
    if (nextComment !== testimonyLength) {
      setNextComment(nextComment + 1)
    } else {
      setNextComment(0)
    }
  }
  const handlePrevComment = () => {
    const testimonyLength = testimonials.nodes.length - 1
    if (nextComment !== 0) {
      setNextComment(nextComment - 1)
    } else {
      setNextComment(testimonyLength)
    }
  }

  return (
    <>
      <Layout
        announcement={!announcement ? "" : announcement}
        countdownData={!countdownData ? "" : countdownData}
      >
        <SEO title={sitemetatitle} description={siteMetaDescription} />
        <MainNav active="home" />
        <Header
          // siteTitle={data.site.siteMetadata?.title || `GreatWeb`}
          heading={mainHeading}
          subheading={mainSubheading}
          background={heroBG}
          contact={contactNumber}
        />

        <section className="portfolio container">
          <div className="portfolio__wrapper">
            <div className="portfolio__menu">
              <ul className="portfolio__menu--list list-unstyled">
                {portfolios.nodes
                  .map((item, index) => (
                    <li
                      key={index}
                      className="portfolio__menu--item"
                      onPointerDown={() => handleActivePortfolio(index)}
                    >
                      <div className="portfolio__menu--imgWrapper">
                        <img
                          src={item.portfolio_acf.companyLogo.sourceUrl}
                          alt={item.title}
                          className={
                            activePortfolio === index
                              ? "portfolio__menu--img--active"
                              : "portfolio__menu--img"
                          }
                        />
                      </div>
                    </li>
                  ))
                  .reverse()}
              </ul>
            </div>

            <div className="portfolio__content">
              <div className="portfolio__content--imgWrapper">
                <img
                  src={
                    portfolios.nodes[activePortfolio].portfolio_acf.image
                      .sourceUrl
                  }
                  alt={portfolios.nodes[activePortfolio].title}
                  className="portfolio__content--img"
                />
              </div>
              <div className="portfolio__content--details">
                <h3 className="portfolio__content--details--heading">
                  {portfolios.nodes[activePortfolio].title}
                </h3>
                <p className="portfolio__content--details--description">
                  {portfolios.nodes[activePortfolio].portfolio_acf.description}
                </p>
                <ul className="portfolio__content--tags list-unstyled">
                  {portfolios.nodes[activePortfolio].portfolio_acf.services.map(
                    (item, index) => (
                      <li
                        key={index}
                        className="portfolio__content--tags--item badge badge-danger"
                      >
                        {item}
                      </li>
                    )
                  )}
                </ul>
                <div className="portfolio__content--website">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`https://${portfolios.nodes[activePortfolio].portfolio_acf.websiteLink}`}
                  >
                    {
                      portfolios.nodes[activePortfolio].portfolio_acf
                        .websiteLink
                    }
                  </a>
                </div>
              </div>
              <div className="portfolio__result">
                <p className="portfolio__result--percent">
                  {
                    portfolios.nodes[activePortfolio].portfolio_acf
                      .resultValuePair.value
                  }
                </p>
                <p className="portfolio__result--description">
                  {
                    portfolios.nodes[activePortfolio].portfolio_acf
                      .resultValuePair.text
                  }
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="focus container">
          <div className="focus__left">
            <h4 className="focus__heading section-title">{heading}</h4>
            {/* <p className="focus__description">{paragraph}</p> */}
            <div
              className="focus__description"
              dangerouslySetInnerHTML={{ __html: paragraph }}
            ></div>

            <ul className="focus__list list-unstyled">
              {focustList.map((item, index) => (
                <li
                  key={index}
                  className="focus__item dflex justify-content-start align-items-center"
                >
                  <div>
                    <FaCheckCircle className="focus__item--icon" />
                  </div>
                  <p className="focus__item--info">{item}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="focus__right">
            <img
              src={sourceUrl || require("../images/focus-img.png")}
              alt={heading}
              className="focus__right--img"
            />
            {/* <Image
              className="focus__right--img"
              fluid={fluid}
              alt="GreatWeb Focus on"
            /> */}
          </div>
        </section>

        <BookStrategyBtnPrimary />
        <div className="principle container">
          <div className="principle__col">
            <h4 className="principle__heading section-title">{pHeading}</h4>

            <ul className="principle__list list-unstyled">
              {principleList.map((item, index) => (
                <li key={index} className="principle__item">
                  <div className="principle__item--iconWrapper">
                    <img
                      src={require(`../images/f${index}.png`)}
                      alt={item}
                      className="principle__item--icon"
                    />
                  </div>
                  <p className="principle__item--label">{item}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="principle__col">
            <div className="principle__imgWrapper">
              <img
                src={pSourceUrl || require("../images/macbook_mockup.png")}
                alt={pHeading}
                className="principle__img"
              />
            </div>
          </div>
        </div>
        <div id="testimony" className="testimony container">
          <h4 className="testimony__heading section-title text-center">
            How Can You Trust Us?
          </h4>
          <div className="testimony__col">
            <div className="testimony__left">
              <div className="testimony__left--imgBGwrapper">
                <img
                  className="testimony__left--imgBG"
                  src={require("../images/tbg.png")}
                  alt="testimony greatweb"
                />
              </div>
              <img
                className="testimony__left--imgUser"
                src={
                  testimonials.nodes[nextComment].testimonial_afc.photo
                    .sourceUrl || require("../images/img4.jpg")
                }
                alt={testimonials.nodes[nextComment].testimonial_afc.author}
              />
            </div>
            <div className="testimony__right">
              <div className="testimony__right--wrapper">
                <i className="testimony__quote fas fa-quote-left"></i>

                <ul className="testimony__rating--list list-unstyled">
                  <li className="testimony__rating--item">
                    <FaStar className="testimony__rating--star" />
                  </li>
                  <li className="testimony__rating--item">
                    <FaStar className="testimony__rating--star" />
                  </li>
                  <li className="testimony__rating--item">
                    <FaStar className="testimony__rating--star" />
                  </li>
                  <li className="testimony__rating--item">
                    <FaStar className="testimony__rating--star" />
                  </li>
                  <li className="testimony__rating--item">
                    <FaStar className="testimony__rating--star" />
                  </li>
                </ul>

                <p className="testimony__message">
                  {testimonials.nodes[nextComment].testimonial_afc.testimony}
                </p>

                <h4 className="testimony__author--name">
                  {testimonials.nodes[nextComment].testimonial_afc.author}
                </h4>
                <p className="testimony__author--position">
                  {testimonials.nodes[nextComment].testimonial_afc.position}
                </p>

                <div className="testimony__prevNext">
                  <FaArrowLeft
                    className="testimony__prevIcon"
                    onClick={handlePrevComment}
                  />
                  <FaArrowRight
                    className="testimony__nextIcon"
                    onClick={handleNextComment}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="process container" style={footerStyle}>
          <div className="process__wrapper">
            <h4 className="process__heading section-title">
              Our Process How We Build Your Website That Helps Achieve Your
              Goals
            </h4>
            <ul className="process__list list-unstyled" style={processStyle}>
              {pProcess.nodes
                .map((item, index) => (
                  <li
                    key={index}
                    className={
                      activeProcess === index
                        ? "process__item--active"
                        : "process__item"
                    }
                    onPointerDown={() => handleActiveProcess(index)}
                  >
                    <div className="process__imgWrapper">
                      <img
                        className="process__img"
                        src={
                          item.process_acf.icon.sourceUrl ||
                          require(`../images/process-${index}.png`)
                        }
                        alt={`GreatWeb ${item.title}`}
                      />
                    </div>
                    <h3 className="process__label">{item.title}</h3>
                  </li>
                ))
                .reverse()}
            </ul>

            <p className="process__description">
              {pProcess.nodes[activeProcess].process_acf.processDescription}
            </p>
          </div>

          <ReadyStarted />
          <BookStrategyBtn />
          <Footer
            facebookLink={facebookLink}
            linkedinLink={linkedinLink}
            supportEmail={supportEmail}
            twiiterLink={twiiterLink}
            companyAddress={companyAddress}
            companyContactNumber={companyContactNumber}
            companyOverview={companyOverview}
          />
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
